<template>
  <AppLayout>
    <template v-slot:appContent>
      <div class="vendor-setting-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0">
          <h4 class="">Vendor/List</h4>
        </div>
        <div class="accordion" id="vendorAccordion">

          <div class="card">
            <div class="card-header" id="settingVendorCodePrefixHeading">
              <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                      data-target="#settingVendorCodePrefixCollapse" aria-expanded="true" aria-controls="collapseVendorCodePrefix">
                <span class="text-capitalize vendor-setting-title">Vendor Code Prefix</span>
              </button>
            </div>
            <div id="settingVendorCodePrefixCollapse" class="collapse" aria-labelledby="settingVendorCodePrefixHeading" data-parent="#vendorAccordion">
              <div class="card-body">
                <SettingVendorCodePrefix :vendor-code-prefix="vendorCodePrefix" />
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-header" id="settingVendorCodeSuffixHeading">
              <button class="btn btn-block text-left w-100" type="button" data-toggle="collapse"
                      data-target="#settingVendorCodeSuffixCollapse" aria-expanded="true" aria-controls="collapseVendorCodeSuffix">
                <span class="text-capitalize vendor-setting-title">Vendor Code Suffix</span>
              </button>
            </div>
            <div id="settingVendorCodeSuffixCollapse" class="collapse" aria-labelledby="settingVendorCodeSuffixHeading" data-parent="#vendorAccordion">
              <div class="card-body">
                <SettingVendorCodeSuffix :vendor-code-suffix="vendorCodeSuffix" />
              </div>
            </div>
          </div>

        </div>

      </div>
    </template>
  </AppLayout>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from "@/layouts/backEnd/AppLayout";
import {mapActions, mapGetters} from "vuex";
import SettingVendorCodePrefix from "@/views/backEnd/settings/vendor/includes/SettingVendorCodePrefix";
import SettingVendorCodeSuffix from "@/views/backEnd/settings/vendor/includes/SettingVendorCodeSuffix";

export default {
  name: "SettingVendorList.vue",
  mixins: [ShowToastMessage, Loader],
  components: {
    AppLayout,
    SettingVendorCodePrefix,
    SettingVendorCodeSuffix,

  },
  data() {
    return {
      getSettingsParams: {
        type: ["vendor"],
        key: [
          "vendor_code_prefix",
          "vendor_code_suffix",
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      vendorCodePrefix: 'appSettings/settingVendorCodePrefix',
      vendorCodeSuffix: 'appSettings/settingVendorCodeSuffix',
    }),
  },
  methods: {
    ...mapActions({
      getSettings: "appSettings/getSettings",
    }),
    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },
  },
  async mounted() {
    await this.loader(true);
    await this.getSettingList();
    await this.loader(false);
  },

}
</script>

<style>
.vendor-setting-wrapper .accordion .card{
  overflow: visible !important;
}
.vendor-setting-title{
  font-size: 16px;
  color: rgb(44, 44, 44);
}
</style>
